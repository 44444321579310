import useResourcesActions from "@/hooks/useResourcesActions";
import { isEmpty } from "lodash";
import React from "react";
import { NotFound, Resource } from "react-admin";

import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  ResourceProps,
  TextField,
  ListGuesser,
  ShowGuesser,
  EditGuesser,
} from "react-admin";
import CustomLoading from "../components/CustomLoading";
import FeedsList from "../dashboard/feeds/FeedsList";
// import FeedsShow from "../dashboard/feeds/FeedsShow";
import FeedsCreate from "../dashboard/feeds/FeedsCreate";
import FeedsEdit from "../dashboard/feeds/FeedsEdit";

const FeedsResource = () => {
  const permissions = useResourcesActions({
    resource: "feeds",
  });


  if (permissions.loading) {
    return <CustomLoading />;
  }

  // if (!permissions?.view) {
  //   return <NotFound title="No permission or Not found page" />;
  // }
 

  return (

    <Resource
      name={"feeds"}
      list={FeedsList}
      // show={FeedsShow}
      edit={FeedsEdit}
      create={FeedsCreate}
    />
  );
};

export default FeedsResource;
