import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomListActions from "@/components/CustomListActions";
import Colors from "@/themes/Colors";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  SimpleShowLayout,
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  Show,
  ResourceProps,
  TextField,
  useDelete,
  useRecordContext,
} from "react-admin";
import Button from '@mui/material/Button';
import db from "../../provider/appProvider";
import CustomDeleteButton from "@/components/CustomDeleteButton";

const FeedsList = (props: ResourceProps["list"]) => {
  
  const permissionActions = useResourcesActions({
    resource: "feeds",
  });
 
  let found: any = null;

  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        // rowClick="show"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        {/* <TextField source="email" color={Colors.primaryDark} label="EMAIL" /> */}
       
        <TextField source="id" label="ID" />
        
        {/* <TextField source="email" color={Colors.primaryDark} label="EMAIL" /> */}
        {/* <BooleanField source="active" label="ACTIVE" /> */}
        <TextField source="fullName" label="FULLNAME" />
        <TextField source="userId" label="USERID" />
        <TextField source="description" label="DESCRIPTION" />
        <TextField source="deactive" label="DEACTIVE" />
      
        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          // <DeleteButton mutationMode="pessimistic" />
          <CustomDeleteButton resource="feeds" />
        )}
        
      </Datagrid>
    </List>
  );
};

export default FeedsList;
