import React from "react";
import {
  SaveButton,
  removeEmpty,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  collectionGroup,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import db from "../provider/appProvider";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const CustomEditButton = ({
  resource,
  overrideData = {},
  identifier = "",
  keepEmpty = false,
}: {
  resource: string;
  overrideData?: {
    [property: string]: any;
  };
  identifier?: string;
  keepEmpty?: boolean;
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { getValues } = useFormContext();
  const [update] = useUpdate();

  const handleClick = async (e: any) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();
    const auth = getAuth();
    

    if (resource === "feeds") {
      try {
        const updateitemRef = query(
          collection(db, "users", data.userId, "feeds")
        );

        const itemSnapshot = await getDocs(updateitemRef);

        itemSnapshot.forEach((doc) => {
          if (doc.id === id) {
            updateDoc(doc.ref, {
              description: data?.description,
              deactive: data?.deactive,
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else if (resource === "exercisetags") {
      try {
        const updateitemRef = query(collection(db, "exercise-tags"));
        const itemSnapshot = await getDocs(updateitemRef);

        itemSnapshot.forEach((doc) => {
          if (doc.id === id) {
            updateDoc(doc.ref, {
              tag: data?.tag,
              active: data?.active,
              vi: data?.vi,
              en: data?.en,
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      update(
        resource,
        {
          id: id || data?.[identifier],
          data: {
            ...removeEmpty(data),
            ...removeEmpty(overrideData),
          },
        },
        {
          onSuccess() {
            notify(`update ${resource} successfully!`);
            redirect("/" + resource);
            setTimeout(() => {
              refresh();
            }, 50);
          },
          onError(error: any) {
            notify(error?.message, {
              type: "error",
            });
          },
        }
      );
    }
  };

  return (
    <SaveButton
      label="SAVE AND UPDATE"
      onClick={handleClick}
      type="button"
      variant="text"
    />
  );
};

export default CustomEditButton;
