import React from "react";

import CustomEditButton from "@/components/CustomEditButton";
import { Grid } from "@mui/material";
import {
  BooleanInput,
  DeleteButton,
  Edit,
  ReferenceArrayInput,
  ResourceProps,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canEdit && <CustomEditButton resource="feeds" />}
      {permissionActions?.canDelete && (
        <DeleteButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

const FeedsEdit = (props: ResourceProps["edit"]) => {
  const permissionActions = useResourcesActions({
    resource: "feeds",
  });

  return (
    <Edit {...props}>
       <SimpleForm
        toolbar={<ListActions permissionActions={permissionActions} />}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"fullName"}
              label="FULLNAME"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"description"}
              label="DESCRIPTION"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              disabled
              source={"userId"}
              label="USERID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              disabled
              source={"id"}
              label="ID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <BooleanInput
            required
            source={"deactive"}
            label="DEACTIVE"
            style={{
              width: "90%",
            }}
          />
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default FeedsEdit;
