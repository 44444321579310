import useResourcesActions from "@/hooks/useResourcesActions";
import { awsConfig } from "@/provider/awsConfig";
import { getThumbnail, uploadFile, listFiles } from "@/services/s3Service";
import { isEmpty } from "lodash";
import React, { useState, useRef } from "react";
import {
  NotFound,
  Resource,
  ImageField,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomLoading from "../../components/CustomLoading";
import CustomUpload from "../../components/S3ImageUploader";
import AWS from "aws-sdk";

const s3 = new AWS.S3();

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUpload = ({ folder }: { folder: string }) => {
  const [category, setCategory] = React.useState("exercises");
  const [filebase64, setFileBase64] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>();
  const refFile = useRef<any>(null);
  const [update] = useUpdate();
  const [progress, setProgress] = React.useState(10);

  const permissions = useResourcesActions({
    resource: "video",
  });

  if (permissions.loading) {
    return <CustomLoading />;
  }

  if (!permissions?.view) {
    return <NotFound title="No permission or Not found page" />;
  }

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleUpload = async () => {
    let folders = "images";

    if (filebase64.indexOf("image/") > -1) folders = "images";
    if (filebase64.indexOf("video/") > -1) folders = "videos";
    if (filebase64.indexOf("audio/") > -1) folders = "sounds";

    const params: AWS.S3.PutObjectRequest = {
      Body: file,
      Bucket: awsConfig.AWS_BUCKET,
      Key: `${category}/${folders}/${file.name}`,
      // Key: `${folder}/${file.name}`
    };
    // let partSize = file.size / (10 * 1024 * 1024);
    await uploadFile(params)
      .then((data) => {
        console.log("data", data);
        // setProgress(Math.round((100 * data.loaded) / data.total))
        setOpen(true);
        setFileBase64(null);
      })
      .catch((err) => console.error(err));
  };

  const removeFile = () => {
    refFile.current.value = null;
    setFile(null);
    setFileBase64(null);
    setPreview(null);
  };

  function convertFile(files: FileList | null) {
    if (files) {
      const fileRef = files[0] || "";
      if (fileRef) {
        setFile(fileRef);
        const objectUrl = URL.createObjectURL(fileRef);
        setPreview(objectUrl);
      }

      const fileType: string = fileRef.type || "";
      console.log("This file upload is of type:", fileType);
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
      // const selectFile = e.target.files[0];
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  return (
    <div className="App">
      <header className="App-header">
        {/* <Box
          sx={{ minWidth: 250 }}
          style={{
            backgroundColor: "#fff",
            padding: "10px 26px 10px 12px",
            borderRadius: 10,
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              // style={{color:"#fff"}}
              labelId="category-select-label"
              id="category-select"
              value={category}
              label="Category"
              onChange={handleChange}
            >
              <MenuItem value={"exercises"}>Exercises</MenuItem>
              <MenuItem value={"avatars"}>Avatars</MenuItem>
              <MenuItem value={"challenges"}>Challenges</MenuItem>
              <MenuItem value={"feeds"}>Feeds</MenuItem>
              <MenuItem value={"notifyMenus"}>NotifyMenus</MenuItem>
              <MenuItem value={"stories"}>Stories</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        Choose an file to to upload
        {/* <form onSubmit={formSubmit}> */}
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            ref={refFile}
            type="file"
            onChange={(e) => convertFile(e.target.files)}
          />
        </Button>
        <hr />
        {filebase64 && (
          <>
            {/* if it's an image */}
            {filebase64.indexOf("image/") > -1 && (
              <img src={filebase64} width={300} />
            )}
            {/* if it's an image */}

            {/* if it's a video */}
            {filebase64.indexOf("video/") > -1 && (
              <video controls>
                <source src={filebase64} />
              </video>
            )}
            {/* if it's a video */}

            {/* if it's a audio (music, sound) */}
            {filebase64.indexOf("audio/") > -1 && (
              <audio controls>
                <source src={filebase64} />
              </audio>
            )}
            {/* if it's a audio (music, sound) */}

            {/* if it's a PDF */}
            {filebase64.indexOf("application/pdf") > -1 && (
              <embed src={filebase64} width="800px" height="2100px" />
            )}
            {/* if it's a PDF */}

            <hr />
            {/* <button> Submit and check the console</button> */}

            <Stack spacing={2} direction="row">
              <Button onClick={handleUpload}>Save</Button>
              <Button onClick={removeFile}>Remove</Button>
            </Stack>
          </>
        )}
        {/* </form> */}
      </header>
      <Box sx={{ width: "100%" }}>
        {/* <LinearProgressWithLabel value={progress} /> */}
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Upload completed successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FileUpload;
