import React from "react";

import {
  SimpleForm,
  TextInput,
  ResourceProps,
  Create,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  Toolbar,
  RadioButtonGroupInput,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";
import { Grid } from "@mui/material";
import CustomCreateButton from "@/components/CustomCreateButton";


const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canCreate && <CustomCreateButton resource="feeds" />}
      
    </Toolbar>
  );
};

const CustomToolBar = () => {
  return (
    <Toolbar>
      <CustomCreateButton resource="feeds" identifier="fullName" />
    </Toolbar>
  );
};

const FeedsCreate = (props: ResourceProps["create"]) => {
  // const permissionActions = useResourcesActions({
  //   resource: "users",
  // });
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"fullName"}
              label="FULLNAME"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"description"}
              label="DESCRIPTION"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"userId"}
              label="USERID"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          <BooleanInput
            required
            source={"deactive"}
            label="DEACTIVE"
            style={{
              width: "90%",
            }}
          />
        </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default FeedsCreate;
