import React, { useState, useRef } from "react";
import { getThumbnail, uploadFile, listFiles } from "@/services/s3Service";
import { awsConfig } from "@/provider/awsConfig";
import {
  SimpleForm,
  TextInput,
  ResourceProps,
  Create,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  SelectInput,
  Toolbar,
  RadioButtonGroupInput,
  DateInput,
  NumberInput,
} from "react-admin";
import useResourcesActions from "../../hooks/useResourcesActions";
import { Grid } from "@mui/material";
import CustomCreateButton from "@/components/CustomCreateButton";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { height } from "@mui/system";

const ListActions = ({
  permissionActions,
}: {
  permissionActions: IPermissionActions;
}) => {
  return (
    <Toolbar>
      {permissionActions?.canCreate && (
        <CustomCreateButton resource="exercises" />
      )}
    </Toolbar>
  );
};

const CustomToolBar = () => {
  return (
    <Toolbar>
      <CustomCreateButton resource="exercises" identifier="exercise" />
    </Toolbar>
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ExercisesCreate = (props: ResourceProps["create"]) => {
  const [dataVideos, setDataVideos] = useState<any>([]);
  const [dataSounds, setDataSounds] = useState<any>([]);
  const [dataImages, setDataImages] = useState<any>([]);
  const [loading, setLoading] = useState<any>("");
  const [file, setFile] = useState<any>(null);
  const [filebase64, setFileBase64] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const refFile = useRef<any>(null);

  // const permissionActions = useResourcesActions({
  //   resource: "users",
  // });

  function convertFile(files: FileList | null) {
    if (files) {
      const fileRef = files[0] || "";
      if (fileRef) {
        setFile(fileRef);
        const objectUrl = URL.createObjectURL(fileRef);
      }

      const fileType: string = fileRef.type || "";
      console.log("This file upload is of type:", fileType);
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev: any) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
      // const selectFile = e.target.files[0];
    }
  }
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleUpload = async () => {
    let folders = "images";

    if (filebase64.indexOf("image/") > -1) folders = "images";
    if (filebase64.indexOf("video/") > -1) folders = "videos";
    if (filebase64.indexOf("audio/") > -1) folders = "sounds";

    const params: AWS.S3.PutObjectRequest = {
      Body: file,
      Bucket: awsConfig.AWS_BUCKET,
      Key: `exercises/${folders}/${file.name}`,
    };
    // let partSize = file.size / (10 * 1024 * 1024);
    await uploadFile(params)
      .then((data) => {
        console.log("data", data);
        // setProgress(Math.round((100 * data.loaded) / data.total))
        setOpen(true);
        setFileBase64(null);
        handleGetAws();
      })
      .catch((err) => console.error(err));
  };

  const removeFile = () => {
    refFile.current.value = null;
    setFile(null);
    setFileBase64(null);
  };

  const toChoices = (items: string[]) =>
    items.map((item) => ({ id: item, name: item }));

  const handleGetAws = async () => {
    const dataAws = await listFiles({ folder: "exercises/videos" });
    const dataAwsSounds = await listFiles({ folder: "exercises/sounds" });
    const dataAwsImages = await listFiles({ folder: "exercises/images" });
    setDataVideos(dataAws);
    setDataSounds(dataAwsSounds);
    setDataImages(dataAwsImages);
  };

  if (loading == "") {
    handleGetAws();
    setLoading(true);
  }

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolBar />}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"tag"}
              label="TAG"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {/* <TextInput
              source={"video"}
              label="VIDEO"
              style={{
                width: "90%",
              }}
            /> */}
            <SelectInput
              style={{
                width: "90%",
              }}
              source="video"
              choices={toChoices(dataVideos)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              source="isPreviewed"
              label="isPreviewed"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              defaultValue="true"
              source="isMusicOn"
              label="isMusicOn"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <NumberInput
              source="order"
              label="order"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <NumberInput
              source="volume"
              label="volume"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"author"}
              label="author"
              style={{
                width: "90%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"title"}
              label="title"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            {/* <TextInput
              required
              source={"sound"}
              label="sound"
              style={{
                width: "90%",
              }}
            /> */}
            <SelectInput
              style={{
                width: "90%",
              }}
              source="sound"
              choices={toChoices(dataSounds)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextInput
              required
              source={"description"}
              label="description"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            {/* <TextInput
              required
              source={"image"}
              label="image"
              style={{
                width: "90%",
              }}
            /> */}
            <SelectInput
              style={{
                width: "90%",
              }}
              source="image"
              choices={toChoices(dataImages)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <DateInput
              source="subtitle"
              label="SUBTITLE"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <BooleanInput
              required
              source={"deactive"}
              label="DEACTIVE"
              style={{
                width: "90%",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                ref={refFile}
                type="file"
                onChange={(e) => convertFile(e.target.files)}
              />
            </Button>
            {filebase64 && (
              <>
                {filebase64.indexOf("image/") > -1 && (
                  <div style={{ marginTop: 10 }}>
                    <img src={filebase64} width={100} />
                  </div>
                )}
                {filebase64.indexOf("video/") > -1 && (
                  <div style={{ marginTop: 10 }}>
                    <video controls height={300}>
                      <source src={filebase64} />
                    </video>
                  </div>
                )}
                {filebase64.indexOf("audio/") > -1 && (
                  <div style={{ marginTop: 10 }}>
                    <audio controls>
                      <source src={filebase64} />
                    </audio>
                  </div>
                )}
              </>
            )}
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button onClick={handleUpload}>Upload</Button>
              <Button onClick={removeFile}>Remove</Button>
            </Stack>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Upload completed successfully!
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default ExercisesCreate;
