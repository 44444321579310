import { isUndefined } from "lodash";
import React from "react";
import {
  SaveButton,
  removeEmpty,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { localStorageStore } from "react-admin";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import db from "../provider/appProvider";

const CustomCreateButton = ({
  resource,
  identifier = "",
  overrideData = {},
  enableRefresh = true,
  recordId,
}: {
  resource: string;
  identifier?: string;
  overrideData?: {
    [property: string]: any;
  };
  enableRefresh?: boolean;
  recordId?: string;
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { getValues } = useFormContext();
  const [create] = useCreate();

  const auth = getAuth();

  const handleClick = async (e: any) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();

    if (resource === "users") {
      try {
        const res = await createUserWithEmailAndPassword(
          auth,
          data?.email,
          data?.email
        );
        await setDoc(doc(db, "users", res.user.uid), {
          email: data?.email,
          timeStamp: serverTimestamp(),
        });
      } catch (err) {
        console.log(err);
      }
    } else if (resource === "feeds") {
      try {
        const addFeed = collection(db, "users", data?.userId, "feeds");

        await addDoc(addFeed, {
          fullName: data?.fullName,
          description: data?.description,
          userId: data?.userId,
          deactive: data?.deactive,
          timestamp: serverTimestamp(),
        });
      } catch (err) {
        console.log(err);
      }
    } else if (resource === "exercisetags") {
      
      try {
        await setDoc(doc(db, "exercise-tags", id), {
          tag: data?.tag,
          active: data?.active,
          vi: data?.vi,
          en: data?.en,
          timestamp: serverTimestamp(),
        });
      } catch (err) {
        console.log(err);
      }
    } else if (resource === "exercises") {
      
      try {
        const addExercises = collection(db, "exercises");
        await addDoc(addExercises, {
          description: data?.description,
          sound: data?.sound,
          title: data?.title,
          subtitle: data?.subtitle,
          video: data?.video,
          tag: data?.tag,
          image: data?.image,
          author: data?.author,
          order: data?.order,
          volume: data?.volume,
          isPreviewed: data?.isPreviewed,
          isMusicOn: data?.isMusicOn,
          deactive: data?.deactive,
          timestamp: serverTimestamp(),
        });
        
      } catch (err) {
        console.log(err);
      }
    } else {
      create(
        resource,
        {
          data: {
            ...removeEmpty(data),
            ...removeEmpty(overrideData),
            id: !isUndefined(id) ? id : recordId || data?.[identifier],
          },
        },
        {
          onSuccess() {
            notify(`Create ${resource} successfully!`);
            redirect("/" + resource);
            setTimeout(() => {
              enableRefresh && refresh();
            }, 50);
          },
          onError(error: any) {
            notify(error?.message, {
              type: "error",
            });
          },
        }
      );
    }
  };

  return (
    <SaveButton
      label="SAVE AND CREATE"
      onClick={handleClick}
      type="button"
      variant="text"
    />
  );
};

export default CustomCreateButton;
