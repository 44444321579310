import React, {useState} from "react";
import useResourcesActions from "../../hooks/useResourcesActions";
import { getThumbnail, uploadFile, listFiles } from "@/services/s3Service";
import CustomListActions from "@/components/CustomListActions";
import Colors from "@/themes/Colors";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  Show,
  ResourceProps,
  TextField,
  ImageField,
  useRecordContext
} from "react-admin";
import db from "../../provider/appProvider";
import CustomDeleteButton from "@/components/CustomDeleteButton";

const VideoField:any = (props: ResourceProps["list"]) => {
  const record = useRecordContext(props);
  return (<div><video src={`${record.video}`} controls width="320" height="240"></video></div>);
}

const FileListVideo = (props: ResourceProps["list"]) => {
  const permissionActions = useResourcesActions({
    resource: "file-video",
  });
  
  let found: any = null;
  VideoField.defaultProps = { label: 'Video' };
  return (
    <List
      {...props}
      // actions={<CustomListActions permissionActions={permissionActions} />}
      // exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        // rowClick="show"
        // rowClick="edit"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
       
          <VideoField source="Video"/>
        <TextField source="id" label="Name"/>
      

        {/* {permissionActions?.canEdit && <EditButton />} */}
        {permissionActions?.canDelete && (
          // <DeleteButton mutationMode="pessimistic" />
          <CustomDeleteButton resource="videos" />
        )}
      </Datagrid>
    </List>
  );
};

export default FileListVideo;
