import React from "react";
import useResourcesActions from "../../hooks/useResourcesActions";

import CustomListActions from "@/components/CustomListActions";
import Colors from "@/themes/Colors";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  Show,
  ResourceProps,
  TextField,
  DateField,
  NumberField,
  ArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";
import db from "../../provider/appProvider";
import CustomDeleteButton from "@/components/CustomDeleteButton";

const ExercisesList = (props: ResourceProps["list"]) => {
  
  const permissionActions = useResourcesActions({
    resource: "exercises",
  });
 
  let found: any = null;
  
  return (
    <List
      {...props}
      actions={<CustomListActions permissionActions={permissionActions} />}
      exporter={permissionActions.canExport ? undefined : false}
      perPage={50}
    >
      <Datagrid
        // rowClick="show"
        // rowClick="edit"
        bulkActionButtons={permissionActions?.canDelete ? undefined : false}
        stickyHeader
        border={1}
        bgcolor="red"
        rowStyle={(_, index) =>
          index % 2
            ? {
                backgroundColor: "#FAFAFA",
                borderWidth: 0,
              }
            : {
                backgroundColor: "#EAEAEA",
                borderWidth: 0,
              }
        }
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#2D9CDB",
            color: "#FFFFFF",
            borderWidth: "0.5px",
          },
          "& .RaDatagrid-rowCell": {
            borderColor: "#BDBDBD",
            borderWidth: "0.5px",
          },
        }}
      >
        <TextField source="id" />
        <TextField source="description" />
        <TextField source="sound" />
        <TextField source="title" />
        <DateField source="subtitle" />
        <TextField source="video" />
        <TextField source="tag" />
        <BooleanField source="isPreviewed" />
        <BooleanField source="isMusicOn" />
        <TextField source="image" />
        <TextField source="author" />
        <NumberField source="order" />
        {/* <ArrayField source="extraInfo"><SingleFieldList><ChipField source="description" /></SingleFieldList></ArrayField> */}
        <BooleanField source="isPreviewd" />
        <TextField source="deactive" />

        {permissionActions?.canEdit && <EditButton />}
        {permissionActions?.canDelete && (
          // <DeleteButton mutationMode="pessimistic" />
          <CustomDeleteButton resource="exercises" />
        )}
      </Datagrid>
    </List>
  );
};

export default ExercisesList;
